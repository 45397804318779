import { navigate, graphql, StaticQuery } from 'gatsby';
import md5 from 'md5';
import React from 'react';

import phpScriptsUrl from '../../phpScriptsUrl';
import Checkbox from '../components/checkbox';
import { Form, FormError, FormHeader, FormItems, FormSubmit } from '../components/form';
import Input from '../components/input';
import Layout from '../components/layout';
import { Content, ContentDesc, Footer, FooterLinkBack, Header, Page } from '../components/page';
import Select from '../components/select';
import Seo from '../components/seo';
import SvgArrowRight from '../svg/arrowRight';
import getString from '../utils/get-string';

class Register extends React.Component {
  state = {
    form: {},
    errorMessages: [],
    submitProcessing: false,
    showPassword: false,
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  togglePassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }))
  }

  handleSuccessSubmitting = ({ data }) => {
    this.setState({
      form: {
        ...this.state.form,
        id: data.id,
        baseUrl: `${this.props.location.protocol}//${this.props.location.host}`,
        confirmationUrl: '/email-confirmation',
      },
    })

    this.sendFormToUser()
    navigate(`/register-affirmation`)
  }

  createErrorMessage = error => {
    const data = error.data || error
    const messages = data.map(err => {
      const code = err.attributes.code

      if (code === 'INVALID_FIELD')
        return err.attributes.details.code === 'VALIDATION_UNIQUE'
          ? `${err.attributes.details.fieldLabel} is already registered`
          : `${err.attributes.details.fieldLabel} is wrong`
      else if (code === 'MISSING_FIELDS')
        return `${err.attributes.details.missingFields.join(', ')} are required`
      else return 'something went wrong'
    })

    if (this._isMounted)
      this.setState({ errorMessages: messages, submitProcessing: false })
  }

  formEncode(obj = this.state.form) {
    var str = []
    for (var p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    return str.join('&')
  }

  sendFormToUser = () => {
    window.fetch(`${phpScriptsUrl}email-confirmation.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: this.formEncode(),
    })
  }

  submitHandler = () => {
    const _this = this
    this.setState({ submitProcessing: true })

    fetch('https://site-api.datocms.com/items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Version': 2,
        Accept: 'application/json',
        Authorization: `Bearer 186716fd2040d981a07945e7a0f0de`,
      },
      body: JSON.stringify({
        data: {
          type: 'item',
          attributes: {
            ...this.state.form,
            emailisverified: false,
            lessons: JSON.stringify([]),
          },
          relationships: {
            item_type: {
              data: {
                type: 'item_type',
                id: '47741',
              },
            },
          },
        },
      }),
    })
      .then(response => {
        _this.setState({ responseOk: response.ok })
        return response
      })
      .then(res => res.json())
      .then(data =>
        _this.state.responseOk
          ? _this.handleSuccessSubmitting(data)
          : _this.createErrorMessage(data)
      )
  }

  handleChange = event => {
    const name = event.target.name
    const value =
      event.target.type !== 'checkbox' && event.target.type !== 'radio'
        ? event.target.type === 'password'
          ? md5(event.target.value)
          : event.target.value
        : event.target.checked

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  render() {
    const yearsOptions = Array.from({ length: 100 }, (v, i) => ({
      value: new Date().getFullYear() - 18 - i,
      label: new Date().getFullYear() - 18 - i,
    }))

    const relationshipOptions = [
      {
        value: 'marketing',
        label: getString(this.props.strings, '11220842'),
      },
      {
        value: 'sales',
        label: getString(this.props.strings, '11220843'),
      },
      {
        value: 'distributor',
        label: getString(this.props.strings, '11220889'),
      },
      {
        value: 'agency',
        label: getString(this.props.strings, '11220897'),
      },
      {
        value: 'beer-lover',
        label: getString(this.props.strings, '11220899'),
      },
    ]

    const countryOptions = [
      { value: 'United States', label: 'United States' },
      { value: 'United Kingdom', label: 'United Kingdom' },
      { value: 'Afghanistan', label: 'Afghanistan' },
      { value: 'Albania', label: 'Albania' },
      { value: 'Algeria', label: 'Algeria' },
      { value: 'American Samoa', label: 'American Samoa' },
      { value: 'Andorra', label: 'Andorra' },
      { value: 'Angola', label: 'Angola' },
      { value: 'Anguilla', label: 'Anguilla' },
      { value: 'Antarctica', label: 'Antarctica' },
      { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
      { value: 'Argentina', label: 'Argentina' },
      { value: 'Armenia', label: 'Armenia' },
      { value: 'Aruba', label: 'Aruba' },
      { value: 'Australia', label: 'Australia' },
      { value: 'Austria', label: 'Austria' },
      { value: 'Azerbaijan', label: 'Azerbaijan' },
      { value: 'Bahamas', label: 'Bahamas' },
      { value: 'Bahrain', label: 'Bahrain' },
      { value: 'Bangladesh', label: 'Bangladesh' },
      { value: 'Barbados', label: 'Barbados' },
      { value: 'Belarus', label: 'Belarus' },
      { value: 'Belgium', label: 'Belgium' },
      { value: 'Belize', label: 'Belize' },
      { value: 'Benin', label: 'Benin' },
      { value: 'Bermuda', label: 'Bermuda' },
      { value: 'Bhutan', label: 'Bhutan' },
      { value: 'Bolivia', label: 'Bolivia' },
      { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
      { value: 'Botswana', label: 'Botswana' },
      { value: 'Bouvet Island', label: 'Bouvet Island' },
      { value: 'Brazil', label: 'Brazil' },
      {
        value: 'British Indian Ocean Territory',
        label: 'British Indian Ocean Territory',
      },
      { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
      { value: 'Bulgaria', label: 'Bulgaria' },
      { value: 'Burkina Faso', label: 'Burkina Faso' },
      { value: 'Burundi', label: 'Burundi' },
      { value: 'Cambodia', label: 'Cambodia' },
      { value: 'Cameroon', label: 'Cameroon' },
      { value: 'Canada', label: 'Canada' },
      { value: 'Cape Verde', label: 'Cape Verde' },
      { value: 'Cayman Islands', label: 'Cayman Islands' },
      { value: 'Central African Republic', label: 'Central African Republic' },
      { value: 'Chad', label: 'Chad' },
      { value: 'Chile', label: 'Chile' },
      { value: 'China', label: 'China' },
      { value: 'Christmas Island', label: 'Christmas Island' },
      { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
      { value: 'Colombia', label: 'Colombia' },
      { value: 'Comoros', label: 'Comoros' },
      { value: 'Congo', label: 'Congo' },
      {
        value: 'Congo, The Democratic Republic of The',
        label: 'Congo, The Democratic Republic of The',
      },
      { value: 'Cook Islands', label: 'Cook Islands' },
      { value: 'Costa Rica', label: 'Costa Rica' },
      { value: "Cote D'ivoire", label: "Cote D'ivoire" },
      { value: 'Croatia', label: 'Croatia' },
      { value: 'Cuba', label: 'Cuba' },
      { value: 'Cyprus', label: 'Cyprus' },
      { value: 'Czech Republic', label: 'Czech Republic' },
      { value: 'Denmark', label: 'Denmark' },
      { value: 'Djibouti', label: 'Djibouti' },
      { value: 'Dominica', label: 'Dominica' },
      { value: 'Dominican Republic', label: 'Dominican Republic' },
      { value: 'Ecuador', label: 'Ecuador' },
      { value: 'Egypt', label: 'Egypt' },
      { value: 'El Salvador', label: 'El Salvador' },
      { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
      { value: 'Eritrea', label: 'Eritrea' },
      { value: 'Estonia', label: 'Estonia' },
      { value: 'Ethiopia', label: 'Ethiopia' },
      {
        value: 'Falkland Islands (Malvinas)',
        label: 'Falkland Islands (Malvinas)',
      },
      { value: 'Faroe Islands', label: 'Faroe Islands' },
      { value: 'Fiji', label: 'Fiji' },
      { value: 'Finland', label: 'Finland' },
      { value: 'France', label: 'France' },
      { value: 'French Guiana', label: 'French Guiana' },
      { value: 'French Polynesia', label: 'French Polynesia' },
      {
        value: 'French Southern Territories',
        label: 'French Southern Territories',
      },
      { value: 'Gabon', label: 'Gabon' },
      { value: 'Gambia', label: 'Gambia' },
      { value: 'Georgia', label: 'Georgia' },
      { value: 'Germany', label: 'Germany' },
      { value: 'Ghana', label: 'Ghana' },
      { value: 'Gibraltar', label: 'Gibraltar' },
      { value: 'Greece', label: 'Greece' },
      { value: 'Greenland', label: 'Greenland' },
      { value: 'Grenada', label: 'Grenada' },
      { value: 'Guadeloupe', label: 'Guadeloupe' },
      { value: 'Guam', label: 'Guam' },
      { value: 'Guatemala', label: 'Guatemala' },
      { value: 'Guinea', label: 'Guinea' },
      { value: 'Guinea-bissau', label: 'Guinea-bissau' },
      { value: 'Guyana', label: 'Guyana' },
      { value: 'Haiti', label: 'Haiti' },
      {
        value: 'Heard Island and Mcdonald Islands',
        label: 'Heard Island and Mcdonald Islands',
      },
      {
        value: 'Holy See (Vatican City State)',
        label: 'Holy See (Vatican City State)',
      },
      { value: 'Honduras', label: 'Honduras' },
      { value: 'Hong Kong', label: 'Hong Kong' },
      { value: 'Hungary', label: 'Hungary' },
      { value: 'Iceland', label: 'Iceland' },
      { value: 'India', label: 'India' },
      { value: 'Indonesia', label: 'Indonesia' },
      {
        value: 'Iran, Islamic Republic of',
        label: 'Iran, Islamic Republic of',
      },
      { value: 'Iraq', label: 'Iraq' },
      { value: 'Ireland', label: 'Ireland' },
      { value: 'Israel', label: 'Israel' },
      { value: 'Italy', label: 'Italy' },
      { value: 'Jamaica', label: 'Jamaica' },
      { value: 'Japan', label: 'Japan' },
      { value: 'Jordan', label: 'Jordan' },
      { value: 'Kazakhstan', label: 'Kazakhstan' },
      { value: 'Kenya', label: 'Kenya' },
      { value: 'Kiribati', label: 'Kiribati' },
      {
        value: "Korea, Democratic People's Republic of",
        label: "Korea, Democratic People's Republic of",
      },
      { value: 'Korea, Republic of', label: 'Korea, Republic of' },
      { value: 'Kuwait', label: 'Kuwait' },
      { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
      {
        value: "Lao People's Democratic Republic",
        label: "Lao People's Democratic Republic",
      },
      { value: 'Latvia', label: 'Latvia' },
      { value: 'Lebanon', label: 'Lebanon' },
      { value: 'Lesotho', label: 'Lesotho' },
      { value: 'Liberia', label: 'Liberia' },
      { value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
      { value: 'Liechtenstein', label: 'Liechtenstein' },
      { value: 'Lithuania', label: 'Lithuania' },
      { value: 'Luxembourg', label: 'Luxembourg' },
      { value: 'Macao', label: 'Macao' },
      {
        value: 'Macedonia, The Former Yugoslav Republic of',
        label: 'Macedonia, The Former Yugoslav Republic of',
      },
      { value: 'Madagascar', label: 'Madagascar' },
      { value: 'Malawi', label: 'Malawi' },
      { value: 'Malaysia', label: 'Malaysia' },
      { value: 'Maldives', label: 'Maldives' },
      { value: 'Mali', label: 'Mali' },
      { value: 'Malta', label: 'Malta' },
      { value: 'Marshall Islands', label: 'Marshall Islands' },
      { value: 'Martinique', label: 'Martinique' },
      { value: 'Mauritania', label: 'Mauritania' },
      { value: 'Mauritius', label: 'Mauritius' },
      { value: 'Mayotte', label: 'Mayotte' },
      { value: 'Mexico', label: 'Mexico' },
      {
        value: 'Micronesia, Federated States of',
        label: 'Micronesia, Federated States of',
      },
      { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
      { value: 'Monaco', label: 'Monaco' },
      { value: 'Mongolia', label: 'Mongolia' },
      { value: 'Montserrat', label: 'Montserrat' },
      { value: 'Morocco', label: 'Morocco' },
      { value: 'Mozambique', label: 'Mozambique' },
      { value: 'Myanmar', label: 'Myanmar' },
      { value: 'Namibia', label: 'Namibia' },
      { value: 'Nauru', label: 'Nauru' },
      { value: 'Nepal', label: 'Nepal' },
      { value: 'Netherlands', label: 'Netherlands' },
      { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
      { value: 'New Caledonia', label: 'New Caledonia' },
      { value: 'New Zealand', label: 'New Zealand' },
      { value: 'Nicaragua', label: 'Nicaragua' },
      { value: 'Niger', label: 'Niger' },
      { value: 'Nigeria', label: 'Nigeria' },
      { value: 'Niue', label: 'Niue' },
      { value: 'Norfolk Island', label: 'Norfolk Island' },
      { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
      { value: 'Norway', label: 'Norway' },
      { value: 'Oman', label: 'Oman' },
      { value: 'Pakistan', label: 'Pakistan' },
      { value: 'Palau', label: 'Palau' },
      {
        value: 'Palestinian Territory, Occupied',
        label: 'Palestinian Territory, Occupied',
      },
      { value: 'Panama', label: 'Panama' },
      { value: 'Papua New Guinea', label: 'Papua New Guinea' },
      { value: 'Paraguay', label: 'Paraguay' },
      { value: 'Peru', label: 'Peru' },
      { value: 'Philippines', label: 'Philippines' },
      { value: 'Pitcairn', label: 'Pitcairn' },
      { value: 'Poland', label: 'Poland' },
      { value: 'Portugal', label: 'Portugal' },
      { value: 'Puerto Rico', label: 'Puerto Rico' },
      { value: 'Qatar', label: 'Qatar' },
      { value: 'Reunion', label: 'Reunion' },
      { value: 'Romania', label: 'Romania' },
      { value: 'Russian Federation', label: 'Russian Federation' },
      { value: 'Rwanda', label: 'Rwanda' },
      { value: 'Saint Helena', label: 'Saint Helena' },
      { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
      { value: 'Saint Lucia', label: 'Saint Lucia' },
      {
        value: 'Saint Pierre and Miquelon',
        label: 'Saint Pierre and Miquelon',
      },
      {
        value: 'Saint Vincent and The Grenadines',
        label: 'Saint Vincent and The Grenadines',
      },
      { value: 'Samoa', label: 'Samoa' },
      { value: 'San Marino', label: 'San Marino' },
      { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
      { value: 'Saudi Arabia', label: 'Saudi Arabia' },
      { value: 'Senegal', label: 'Senegal' },
      { value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
      { value: 'Seychelles', label: 'Seychelles' },
      { value: 'Sierra Leone', label: 'Sierra Leone' },
      { value: 'Singapore', label: 'Singapore' },
      { value: 'Slovakia', label: 'Slovakia' },
      { value: 'Slovenia', label: 'Slovenia' },
      { value: 'Solomon Islands', label: 'Solomon Islands' },
      { value: 'Somalia', label: 'Somalia' },
      { value: 'South Africa', label: 'South Africa' },
      {
        value: 'South Georgia and The South Sandwich Islands',
        label: 'South Georgia and The South Sandwich Islands',
      },
      { value: 'Spain', label: 'Spain' },
      { value: 'Sri Lanka', label: 'Sri Lanka' },
      { value: 'Sudan', label: 'Sudan' },
      { value: 'Suriname', label: 'Suriname' },
      { value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
      { value: 'Swaziland', label: 'Swaziland' },
      { value: 'Sweden', label: 'Sweden' },
      { value: 'Switzerland', label: 'Switzerland' },
      { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
      {
        value: 'Taiwan, Province of China',
        label: 'Taiwan, Province of China',
      },
      { value: 'Tajikistan', label: 'Tajikistan' },
      {
        value: 'Tanzania, United Republic of',
        label: 'Tanzania, United Republic of',
      },
      { value: 'Thailand', label: 'Thailand' },
      { value: 'Timor-leste', label: 'Timor-leste' },
      { value: 'Togo', label: 'Togo' },
      { value: 'Tokelau', label: 'Tokelau' },
      { value: 'Tonga', label: 'Tonga' },
      { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
      { value: 'Tunisia', label: 'Tunisia' },
      { value: 'Turkey', label: 'Turkey' },
      { value: 'Turkmenistan', label: 'Turkmenistan' },
      { value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
      { value: 'Tuvalu', label: 'Tuvalu' },
      { value: 'Uganda', label: 'Uganda' },
      { value: 'Ukraine', label: 'Ukraine' },
      { value: 'United Arab Emirates', label: 'United Arab Emirates' },
      { value: 'United Kingdom', label: 'United Kingdom' },
      { value: 'United States', label: 'United States' },
      {
        value: 'United States Minor Outlying Islands',
        label: 'United States Minor Outlying Islands',
      },
      { value: 'Uruguay', label: 'Uruguay' },
      { value: 'Uzbekistan', label: 'Uzbekistan' },
      { value: 'Vanuatu', label: 'Vanuatu' },
      { value: 'Venezuela', label: 'Venezuela' },
      { value: 'Viet Nam', label: 'Viet Nam' },
      { value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
      { value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
      { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
      { value: 'Western Sahara', label: 'Western Sahara' },
      { value: 'Yemen', label: 'Yemen' },
      { value: 'Zambia', label: 'Zambia' },
      { value: 'Zimbabwe', label: 'Zimbabwe' },
    ]

    return (
      <Layout>
        <Seo title={getString(this.props.strings, "11220905")} />

        <Page type="short">
          <Form onSubmit={this.submitHandler}>
            <Header closeUrl="/lessons">{getString(this.props.strings, "11220823")}</Header>
            <Content>
              <FormHeader>
                <ContentDesc>{getString(this.props.strings, "11220823")}</ContentDesc>
                <h1>{getString(this.props.strings, "11220824")}</h1>
              </FormHeader>

              <FormItems>
                <dt>
                  <label htmlFor="name">{getString(this.props.strings, "11220828")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="name"
                    type="text"
                    name="name"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>
                <dt>
                  <label htmlFor="birth">{getString(this.props.strings, "11220831")}</label>
                </dt>
                <dd>
                  <Select
                    name="birth"
                    id="birth"
                    options={yearsOptions}
                    size="lg"
                    required={true}
                    placeholder={getString(this.props.strings, "11220832")}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="email">{getString(this.props.strings, "11220920")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="email"
                    type="email"
                    name="email"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="company">{getString(this.props.strings, "11220836")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="company"
                    type="text"
                    name="company"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="country">{getString(this.props.strings, "11220837")}</label>
                </dt>
                <dd>
                  <Select
                    name="country"
                    id="country"
                    options={countryOptions}
                    size="lg"
                    required={true}
                    placeholder={getString(this.props.strings, "11220839")}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="relationship">
                    {getString(this.props.strings, "11220840")}
                  </label>
                </dt>
                <dd>
                  <Select
                    name="relationship"
                    id="relationship"
                    options={relationshipOptions}
                    size="lg"
                    required={true}
                    placeholder={getString(this.props.strings, "11220841")}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="password">{getString(this.props.strings, "11220900")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    name="password"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>
              </FormItems>

              <Checkbox value={getString(this.props.strings, "11220902")} onChange={this.togglePassword} />

              {this.state.errorMessages.length > 0 &&
                this.state.errorMessages.map((i, k) => (
                  <FormError key={k}>{i}</FormError>
                ))}

              <div />
            </Content>
            <Footer>
              <Checkbox
                name="agreement"
                value="agreement"
                required={true}
                onChange={this.handleChange}
              >
                { /* terms of use */}
                <a href="https://www.asahigroup-holdings.com/privacy_policy/05/">
                  利用規約
                </a>{' '}
                と{' '}
                { /* privacy policy */}
                <a href="https://www.asahigroup-holdings.com/privacy_policy/">
                  プライバシー
                </a>
                ポリシーに同意します
              </Checkbox>

              <FormSubmit disabled={this.state.submitProcessing}>
                {getString(this.props.strings, "11220905")}
              </FormSubmit>

              <FooterLinkBack to="/login">
                {getString(this.props.strings, "11220906")}
                <SvgArrowRight />
              </FooterLinkBack>
            </Footer>
          </Form>
        </Page>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220842", "11220843", "11220889", "11220897", "11220899", "11220905", "11220823", "11220824", "11220828", "11220910", "11220831", "11220832", "11220920", "11220836", "11220837", "11220839", "11220840", "11220841", "11220900", "11220902", "11220906"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Register {...data} {...props} />}
  />
)
